import IEvent from '@/types/Event';
import IUser from '@/types/User';
import { PropType } from 'vue';

export type TypeName =
  | 'all'
  | 'channel'
  | 'learnlist'
  | 'item'
  | 'event'
  | 'user'
  | 'team'
  | 'survey'
  | 'quiz'
  | 'partial';

export type ResponseInterface<T> = T extends 'all'
  ? IFullTileResponse
  : T extends 'channel'
  ? IChannelTileResponse
  : T extends 'learnlist'
  ? ILearnlistTileResponse
  : T extends 'item'
  ? IBaseTileResponse
  : T extends 'event'
  ? IEventTileResponse
  : T extends 'user'
  ? IUserTileResponse
  : T extends 'team'
  ? ITeamTileResponse
  : T extends 'survey'
  ? ISurveyTileResponse
  : T extends 'quiz'
  ? ISurveyTileResponse
  : T extends 'partial'
  ? Partial<IFullTileResponse>
  : never;

export function useTileProps<T extends TypeName>(type: T) {
  return {
    displayName: String,
    itemImageUrl: String,
    subHeading: String,
    type: String,
    showingWidget: Boolean,
    json: {
      type: Object as PropType<ResponseInterface<typeof type>>,
      required: true,
    },
    locked: Boolean,
    loading: Boolean,
    useLegacyMenu: Boolean,
    previewing: {
      type: Boolean,
      default: false,
    },
    modalCopy: Object,
    lockedUntilTasked: Boolean,
    userEndpoint: String,
    carouselNoticeDisplayed: Boolean,
    learnlistNoticeDisplayed: Boolean,
    availableActions: Object,
  };
}

export interface IBaseTileResponse {
  available_actions_path: string;
  average_rating: number;
  carousel_id: number | null;
  completed: boolean;
  delete_path: string;
  description: string;
  expired: boolean;
  hidden: boolean;
  is_new: boolean;
  id: number;
  image_url: string;
  image_urls?: string[];
  item_type: string; // TODO - replace with possible item types
  learnlist_id: number | null;
  liked: boolean;
  name: string;
  ratings_count: number;
  show_label_new: boolean;
  tile_type: 'Item' | 'User' | 'Learnlist' | 'Event';
  total_time: string;
  type_icon: string;
  type_label: string;
  tint: string;
  url: string;
  deadline: string;
  overdue: boolean;
  mandatory: boolean;
  progress: string;
  type: string;
  tile_image_type: string;
  event?: IEvent;
  tasked?: boolean;
}

export interface ILockedTileResponse extends IBaseTileResponse {
  locked: boolean;
  lockedUntilTasked: boolean;
}

interface ISurveyTileResponse extends IBaseTileResponse {
  questions_count: number;
}

interface IChannelTileResponse extends IBaseTileResponse {
  learnlists_count: number;
  carousel_items_count: number;
}
interface ILearnlistTileResponse extends IBaseTileResponse {
  learnlist_items_count: number;
  questions_count: number;
  time: string;
  user: IUser;
  assessor: IUser;
}

interface IEventTileResponse extends IBaseTileResponse {
  event_sessions_count?: number;
  location?: string; // HTML containing icon for zoom/map pin etc
  host?: string;
  event?: IEvent;
  interested_path?: string;
  dates?: string;
  details?: string;
  event_id?: number;
  other_sessions_count?: number;
}

export interface IUserTileResponse extends IBaseTileResponse {
  full_name?: string;
  job_title?: string;
  specialisms?: string[] | undefined;
  custom_fields?: { [key: string]: string };
  show_path?: string;
  avatar?: string;
}

interface ITeamTileResponse extends IBaseTileResponse {
  joined?: boolean;
  applied?: boolean;
  privacy?: string;
}

export interface IFullTileResponse
  extends IChannelTileResponse,
    ILearnlistTileResponse,
    IEventTileResponse,
    IUserTileResponse,
    ITeamTileResponse {}

export interface IContentResponseList {
  items: IBaseTileResponse[];
  carousels: IChannelTileResponse[];
  learnlists: ILearnlistTileResponse[];
  experts: IUserTileResponse[];
  event_sessions: IEventTileResponse[];
}
