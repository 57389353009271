<script lang="ts">
export default {
  name: 'ContentLogFilters',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import _ from 'lodash';
import { Ref, computed, defineProps, nextTick, ref, watch, defineEmits } from 'vue';
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
import { ActivityableTypes } from '@/types/content-search/ActivityableTypes';
import { FilterQuery } from '@/types/DataFilter';
import { IReport } from '@/types/Report';

const { t } = useI18n();
const { apiClient } = useApiClient();
const emit = defineEmits([
  'reload-content-with-filters',
  'filters-all-valid',
  'filters-not-valid',
  'close-content-filters',
]);

const props = defineProps<{
  taskTitlesEndpoint: string;
  filtersEndpoint: string;
  usersEndpoint?: string;
  activityableTypes?: ActivityableTypes[];
  initTeamId?: number;
  initAdvancedGroupingId?: number;
  parentSelectedType: ActivityableTypes;
  initParams: { [key: string]: string };
  searchOptGroups?: string[];
  initReport?: IReport;
}>();

// Refs replacing data properties
const selectedType: Ref<ActivityableTypes | undefined> = ref(props.parentSelectedType);
const expandFilters = ref(true);
const globalConnector = ref('and');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterGroups: Ref<
  { allValid: boolean; filters: Array<FilterQuery>; connector: string; key: string }[]
> = ref([]);
const propertyOptGroups = ref([]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const properties: Ref<any[]> = ref([]);
const updating = ref(false);
const error = ref();
const searchEndpoint = ref('');
const teamId = ref(props.initTeamId);
const advancedGroupingId = ref(props.initAdvancedGroupingId);
const includeSubTeams = ref(false);
const primaryTeamMembersOnly = ref(false);
const includeSubManagers = ref(false);
const selectedManagerId = ref(null);

// Computed
const allValid = computed(() => {
  return !_.isEmpty(filterGroups.value) && _.every(filterGroups.value, 'allValid');
});

const computedFiltersEndpoint = computed(() => {
  return `${props.filtersEndpoint}?type=${selectedType.value?.model}`;
});

const initFilter = computed(() => {
  let filter = {};
  if (props.initParams && props.initParams.taskable_ids) {
    const property = properties.value.find((p) => {
      return p.attribute === 'title';
    });

    const operator = property?.operators.find((o) => {
      return o.operator === '=';
    });

    filter = {
      property: property,
      property_id: property?.id,
      property_unique_id: property?.property_unique_id,
      operatorObject: operator,
      attribute: 'title',
      value: props.initParams.taskable_ids, // this expects format of "id-type", for example "123-Item"
      model: 'Task',
      operator: '=',
      valid: true,
    };
  }
  return filter;
});

// Methods
const getFilters = () => {
  return apiClient.get(computedFiltersEndpoint.value).then(
    (response) => {
      searchEndpoint.value = response.data.searchEndpoint;
      propertyOptGroups.value = response.data.propertyOptGroups;
      properties.value = response.data.properties;
      error.value = null;

      if (props.initParams && props.initParams.taskable_ids) {
        addFilterGroup({}, false);
        if (initFilter.value) {
          addFilter(filterGroups.value[0], initFilter.value);
          filterGroups.value[0].allValid = true;
          nextTick(() => {
            applyFilters();
          });
        }
      } else if (filterGroups.value.length === 0) {
        addFilterGroup({}, true);
      }
    },
    () => {
      error.value = 'Unable to load advanced filters...';
    }
  );
};

const addFilterGroup = (newFilterGroup, addEmptyFilter) => {
  _.defaults(newFilterGroup, {
    allValid: false,
    connector: 'and',
    filters: [],
  });

  newFilterGroup.key = _.uniqueId();
  filterGroups.value.push(newFilterGroup);

  if (addEmptyFilter) {
    addFilter(_.last(filterGroups.value), {});
  }
};

const addFilter = (filterGroup, newFilter) => {
  _.defaults(newFilter, {
    model: null,
    polymorphic_type: null,
    polymorphic_id: null,
    operator: null,
    time_operator: null,
    time_value: null,
    value: null,
    attribute: null,
    count: false,
    valid: false,
    property_id: null,
    property_unique_id: null,
    event_id: null,
    property: {},
    operatorObject: {},
    timeOperatorObject: {},
    behaviour_operator: null,
    is_operator: null,
    boolean: null,
  });
  newFilter.key = _.uniqueId();
  filterGroup.filters.push(newFilter);
  filterGroup.allValid = newFilter.valid;
};

const removeFilter = (filterGroupIndex, filterIndex) => {
  filterGroups.value[filterGroupIndex].filters.splice(filterIndex, 1);
  // if 0 or 1 filter left in filter group, reset connector to "and"
  if (filterGroups.value[filterGroupIndex].filters.length < 2) {
    filterGroups.value[filterGroupIndex].connector = 'and';
  }
  if (filterGroups.value[filterGroupIndex].filters.length === 0) {
    filterGroups.value.splice(filterGroupIndex, 1);
    if (filterGroups.value.length === 0) {
      emit('reload-content-with-filters', null);
    }
  } else {
    filterGroups.value[filterGroupIndex].allValid = _.every(
      filterGroups.value[filterGroupIndex].filters,
      'valid'
    );
  }
};

const handleFilterChanged = (filterGroupIndex, filterIndex, filterParams) => {
  if (
    filterGroups.value[filterGroupIndex] &&
    filterGroups.value[filterGroupIndex].filters[filterIndex]
  ) {
    filterGroups.value[filterGroupIndex].filters[filterIndex].property_id =
      filterParams.property_id;
    filterGroups.value[filterGroupIndex].filters[filterIndex].property_unique_id =
      filterParams.property_unique_id;
    filterGroups.value[filterGroupIndex].filters[filterIndex].valid = filterParams.valid;
    filterGroups.value[filterGroupIndex].filters[filterIndex].model = filterParams.model;
    filterGroups.value[filterGroupIndex].filters[filterIndex].operator = filterParams.operator;
    filterGroups.value[filterGroupIndex].filters[filterIndex].time_operator =
      filterParams.time_operator;
    filterGroups.value[filterGroupIndex].filters[filterIndex].behaviour_operator =
      filterParams.behaviour_operator;
    filterGroups.value[filterGroupIndex].filters[filterIndex].value = filterParams.value;
    filterGroups.value[filterGroupIndex].filters[filterIndex].event_id = filterParams.event_id;
    filterGroups.value[filterGroupIndex].filters[filterIndex].time_value = filterParams.time_value;
    filterGroups.value[filterGroupIndex].filters[filterIndex].time_value_to =
      filterParams.time_value_to;
    filterGroups.value[filterGroupIndex].filters[filterIndex].polymorphic_type =
      filterParams.polymorphic_type;
    filterGroups.value[filterGroupIndex].filters[filterIndex].polymorphic_id =
      filterParams.polymorphic_id;
    filterGroups.value[filterGroupIndex].filters[filterIndex].attribute = filterParams.attribute;
    filterGroups.value[filterGroupIndex].filters[filterIndex].boolean = filterParams.boolean;
    filterGroups.value[filterGroupIndex].filters[filterIndex].count = filterParams.count;
    filterGroups.value[filterGroupIndex].allValid = _.every(
      filterGroups.value[filterGroupIndex].filters,
      'valid'
    );
  }
};

const showFilterConnector = (filterGroup, filter) => {
  return filter !== _.last(filterGroup.filters);
};

const showGlobalConnector = (filterGroup) => {
  return filterGroup !== _.last(filterGroups.value);
};

const applyFilters = () => {
  if (allValid.value) {
    emit('reload-content-with-filters', getCurrentFilterParams(), {
      teamId: teamId.value,
      advancedGroupingId: advancedGroupingId.value,
      selectedType: selectedType.value,
    });
  }
};

const clearFilters = () => {
  filterGroups.value = [];
  emit('reload-content-with-filters', null);
  emit('close-content-filters');
};

const getCurrentFilterParams = () => {
  const queryObjectParams: {
    global_connector: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter_groups: any[];
  } = {
    global_connector: globalConnector.value,
    filter_groups: [],
  };

  filterGroups.value.forEach(function (fg, fgIndex) {
    queryObjectParams.filter_groups[fgIndex] = {};
    queryObjectParams.filter_groups[fgIndex].connector = fg.connector;
    queryObjectParams.filter_groups[fgIndex].queries = [];
    fg.filters.forEach(function (f, i) {
      queryObjectParams.filter_groups[fgIndex].queries[i] = _.clone(f);
    });
  });
  return queryObjectParams;
};

const loadReport = (report) => {
  const assignFilters = () => {
    globalConnector.value = report.filters?.global_connector;
    filterGroups.value = [];

    if (report.filters?.filter_groups) {
      report.filters.filter_groups.forEach((fg, fgIndex) => {
        const newFilterFilterGroup = {
          allValid: true,
          connector: fg.connector,
        };

        addFilterGroup(newFilterFilterGroup, false);

        if (report.starts_at && report.ends_at) {
          window.emitter.emit('apply-dates', {
            startsAt: report.starts_at,
            endsAt: report.ends_at,
          });
        }

        if (fg.queries) {
          fg.queries.forEach(function (f) {
            const newFilter = {
              ...f,
              valid: true,
            };

            if (f.property_unique_id) {
              newFilter.property = _.find(properties.value, [
                'property_unique_id',
                f.property_unique_id,
              ]);
            } else {
              newFilter.property = _.find(properties.value, ['id', f.property_id]);
            }
            if (newFilter.property) {
              newFilter.operatorObject = _.find(newFilter.property.operators, [
                'operator',
                f.operator,
              ]);
              newFilter.timeOperatorObject = _.find(newFilter.property.time_operators, [
                'operator',
                f.time_operator,
              ]);
            }
            addFilter(filterGroups.value[fgIndex], newFilter);
          });
        }
      });
    }

    if (report.team_id) {
      teamId.value = report.team_id;
      includeSubTeams.value = report.include_sub_teams;
      primaryTeamMembersOnly.value = report.primary_team_members_only;

      window.emitter.emit('apply-team', {
        newTeamId: teamId.value,
        newIncludeSubTeams: includeSubTeams.value,
        newPrimaryTeamMembersOnly: primaryTeamMembersOnly.value,
      });
    } else if (report.advanced_grouping_id) {
      advancedGroupingId.value = report.advanced_grouping_id;
      window.emitter.emit('apply-advanced-grouping', advancedGroupingId.value);
    } else if (report.include_sub_managers || report.manager_id) {
      includeSubManagers.value = report.include_sub_managers;
      selectedManagerId.value = report.manager_id;
      window.emitter.emit('managed-user-changed', {
        subManagedUsers: includeSubManagers.value,
        selectedUser: null,
        selectedUserId: selectedManagerId.value,
      });
    }

    window.emitter.emit('report-loaded', report);

    nextTick(() => {
      applyFilters();
      updating.value = false;
    });
  };

  const reportModelName: string = report.filters?.filter_groups[0].queries[0].model;
  const typeChangeRequired = reportModelName && reportModelName !== selectedType.value?.model;
  if (typeChangeRequired) {
    selectedType.value = props.activityableTypes?.find(
      (activityableType) => activityableType.model === reportModelName
    );
    getFilters().then(assignFilters);
  } else {
    assignFilters();
  }
};

const typeChanged = (newType) => {
  selectedType.value = newType;
  filterGroups.value = [];
  getFilters();
};

// Watchers
watch(
  () => props.initTeamId,
  (newVal) => {
    teamId.value = newVal;
  }
);

watch(
  () => props.initAdvancedGroupingId,
  (newVal) => {
    advancedGroupingId.value = newVal;
  }
);

watch(
  () => props.parentSelectedType,
  (newType) => {
    if (selectedType.value && newType.model === selectedType.value.model) {
      return;
    }
    typeChanged(newType);
  }
);

watch(
  () => allValid.value,
  (newValue) => {
    if (newValue) {
      emit('filters-all-valid');
    } else {
      emit('filters-not-valid');
    }
  }
);

watch(
  () => props.initReport,
  (newReport) => {
    if (!newReport) return;
    updating.value = true;
    loadReport(newReport);
  },
  {
    immediate: true,
  }
);

getFilters();

window.emitter.on('apply-filters-before-save', applyFilters);
window.emitter.on('reset-filters', clearFilters);
</script>

<template>
  <div class="content-log-filters">
    <div class="panel filter filter--with-groups">
      <div class="panel-heading filter__heading">
        <a
          href="#"
          class="table--data__sorter table--data__sorter--sorting"
          :class="{ 'table--data__sorter--asc': !expandFilters }"
          @click.prevent="expandFilters = !expandFilters"
        >
          {{ t('vue_templates.activities.content_log.content_log_filters.advanced_filters') }}
        </a>
      </div>

      <div v-show="expandFilters" class="panel-body">
        <p v-if="error" class="text-muted">{{ error }}</p>
        <div v-else-if="updating">
          <div class="filter__group">
            <div v-for="i in 3" :key="i" class="filter__group-query">
              <div class="filter__group-query-field">
                <div class="skeleton-loading"></div>
              </div>
            </div>
            <div class="filter__connector">
              <div class="skeleton-loading.h-20px"></div>
            </div>
            <div class="filter__group-query">
              <div v-for="i in 3" :key="i" class="filter__group-query-field">
                <div class="skeleton-loading"></div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div>
              <div class="skeleton-loading h-20px w-80px m-t-1 inline"></div>
              <div class="skeleton-loading pull-right w-120px h-30px inline"></div>
            </div>
          </div>
        </div>
        <!-- filter groups -->
        <div
          v-for="(filterGroup, fgIndex) in filterGroups"
          v-else
          :key="filterGroup.key"
          data-test-id="content-log-filter-group"
        >
          <div class="filter__group">
            <component
              :is="'content-log-single-filter'"
              v-for="(filter, index) in filterGroup.filters"
              :key="filter.key"
              :init-filter="filter"
              :search-endpoint="searchEndpoint"
              :task-titles-endpoint="taskTitlesEndpoint"
              :filters-endpoint="filtersEndpoint"
              :users-endpoint="usersEndpoint"
              :activityable-types="activityableTypes"
              :filter-group-index="fgIndex"
              :index="index"
              :properties="properties"
              :property-opt-groups="propertyOptGroups"
              :connector="filterGroup.connector"
              :show-filter-connector="showFilterConnector(filterGroup, filter)"
              :search-opt-groups="searchOptGroups"
              :data-test-id="`filter-group-${index}`"
              :selected-type="selectedType"
              @remove="removeFilter"
              @changed="handleFilterChanged"
              @apply="applyFilters"
            />
            <div class="clearfix" />
            <span
              class="btn--add-centered btn--add-centered--sm m-t-1"
              tabindex="0"
              role="button"
              data-test-id="add-filter-button"
              @click.prevent="addFilter(filterGroup, {})"
              @keydown="addFilter(filterGroup, {})"
            >
              <i class="ic ic-add-circle"></i>
              {{ t('vue_templates.activities.content_log.content_log_filters.add_filter') }}
            </span>
            <div v-if="filterGroup.filters.length > 1" class="btn-group pull-right">
              <button
                class="btn btn--size-s"
                type="button"
                :class="[
                  filterGroup.connector === 'and' ? 'btn--light-purple active' : 'btn-default',
                ]"
                @click.prevent="filterGroup.connector = 'and'"
              >
                {{ t('helpers.common.and').toUpperCase() }}
              </button>
              <button
                class="btn btn--size-s"
                type="button"
                :class="[filterGroup.connector === 'or' ? 'btn--light-teal active' : 'btn-default']"
                @click.prevent="filterGroup.connector = 'or'"
              >
                {{ t('helpers.common.or').toUpperCase() }}
              </button>
            </div>
            <div class="clearfix" />
          </div>
          <div
            v-if="showGlobalConnector(filterGroup) && globalConnector === 'and'"
            class="filter__connector filter__connector--and"
          >
            {{ t('helpers.common.and').toUpperCase() }}
          </div>
          <div
            v-else-if="showGlobalConnector(filterGroup)"
            class="filter__connector filter__connector--or"
          >
            {{ t('helpers.common.or').toUpperCase() }}
          </div>
        </div>

        <span
          class="btn--add-centered btn--add-centered--sm m-y-2"
          tabindex="0"
          role="button"
          @click.prevent="addFilterGroup({}, true)"
          @keydown="addFilterGroup({}, true)"
        >
          <i class="ic ic-add-circle"></i>
          {{ t('vue_templates.activities.content_log.content_log_filters.add_filter_group') }}
        </span>

        <div v-if="filterGroups.length > 1" class="btn-group pull-right m-t-1 m-l-1">
          <button
            class="btn btn-default btn--size-s"
            type="button"
            :class="[globalConnector === 'and' ? 'btn--light-purple active' : 'btn-default']"
            @click.prevent="globalConnector = 'and'"
          >
            {{ t('helpers.common.and').toUpperCase() }}
          </button>
          <button
            class="btn btn-default btn--size-s"
            type="button"
            :class="[globalConnector === 'or' ? 'btn--light-teal active' : 'btn-default']"
            @click.prevent="globalConnector = 'or'"
          >
            {{ t('helpers.common.or').toUpperCase() }}
          </button>
        </div>
        <div class=".clearfix"></div>
        <button
          class="btn btn--size-s btn-secondary"
          data-test-id="content-log-filters-cancel"
          @click.prevent="clearFilters"
        >
          {{ t('helpers.buttons.cancel') }}
        </button>
        <button
          v-show="filterGroups.length > 0"
          class="btn btn--size-s btn-primary pull-right"
          :disabled="!allValid"
          data-test-id="apply-filters-button"
          @click.prevent="applyFilters"
        >
          {{ t('vue_templates.activities.content_log.content_log_filters.apply_filters') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-log-filters {
  color: inherit;
}
</style>
