/* eslint-disable no-param-reassign */
import { IApiClient } from '@/types/composables/useApiClient';
import { ref } from 'vue';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function useAtMention(apiClient: IApiClient) {
  const editorInstance = ref();
  const at = '@';
  const buildInsertTemplate = (show_path: string, id: number, name: string): string => {
    return `
      <span class="atwho-inserted" data-atwho-at-query="@" contenteditable="false">
        <a href="${show_path}" data-user-id="${id}">${at}${name}</a>
      </span>
    `;
  };
  const initialiseTinymceAtMention = (
    targetElement: any,
    endpoint: string,
    params: any,
    header: string
  ) => {
    const displayTpl =
      "<li class='users-dropdown-option users-dropdown-option--atwho'><img src='${avatar}'/> <b>${name}</b><br><small><i class='ic ${role_icon}'></i>${job_title}</small></li>";

    /* istanbul ignore next */
    ($(targetElement) as IJqueryAtWho).atwho({
      at,
      displayTpl: displayTpl,
      insertTpl: "<a href='${show_path}' data-user-id=${id}>${atwho-at}${mention_name}</a>",
      headerTpl: `<p class='text-muted m-a-1 atwho--header'>${header}</p>`,
      callbacks: {
        remoteFilter: function (q: any, callback: (data: any) => unknown) {
          params.search = q;
          return apiClient.get(endpoint, { params }).then((response) => {
            return callback(response.body);
          });
        },

        matcher: function (flag, subtext, should_start_with_space) {
          const regexp = new RegExp(
            flag + '([A-Za-z0-9_ +-]*)$|' + flag + '([^\\x00-\\xff]*)$',
            'gi'
          );
          flag = flag.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
          if (should_start_with_space) {
            flag = '(?:^|\\s)' + flag;
          }
          const match = regexp.exec(subtext);
          if (match) {
            return match[2] || match[1];
          } else {
            return null;
          }
        },
        beforeReposition: function (offset: any) {
          offset.top += 20;
          return offset;
        },
      },
    });
  };

  const buildTinymceAtMentionParams: TinyMceParamsFunction = (
    mentionedInType,
    entityId,
    teamId?: number,
    isPrivate = false
  ): ITinyMceAtMentionParams => {
    const params: ITinyMceAtMentionParams = {
      mentioned_in_type: mentionedInType,
      mentioned_in_id: entityId,
      private: isPrivate,
    };

    if (teamId) {
      params.team_id = teamId;
    }

    return params;
  };

  return {
    initialiseTinymceAtMention,
    buildTinymceAtMentionParams,
    buildInsertTemplate,
    editorInstance,
  };
}

interface IJqueryAtWho extends JQuery {
  atwho: (args: IJQueryAtWhoArguments) => undefined;
}

interface IJQueryAtWhoArguments {
  at: string;
  displayTpl: string;
  insertTpl: string;
  headerTpl: string;
  callbacks: {
    remoteFilter: (q: any, callback: (data: any) => unknown) => any;
    matcher: (flag: any, subtext: any, should_start_with_space: boolean) => any;
    beforeReposition: (offset: any) => any;
  };
}

type TinyMceParamsFunction = (
  mentionedInType: string,
  entityId: string,
  teamId?: number,
  isPrivate?: boolean
) => ITinyMceAtMentionParams;
interface ITinyMceAtMentionParams {
  mentioned_in_type: string;
  mentioned_in_id: string;
  private: boolean;
  team_id?: number;
}

export interface ITinyMceAtMentionProps {
  endpoint: string;
  teamId?: number;
  model: string;
  entityId: string;
  entityType: string;
}
