<template>
  <div>
    <SearchInput
      v-model="search"
      :placeholder="t('vue_templates.skills.skills_manage_log.user_search_placeholder')"
      data-test-id="users-simple-list-search-input"
      type="discover-content"
    />
    <LoadingSpinner v-if="loading" data-test-id="users-simple-list-loading" />
    <p v-else-if="users.length == 0" class="m-t-3 text-center text-muted">
      {{ t('vue_templates.quick_add.no_results') }}
    </p>
    <div v-else-if="search.length > 0" class="m-t-1 users-simple-list__results-for">
      <strong>{{ t('vue_templates.quick_add.results_for') }}</strong>
      <em>{{ search }}</em>
    </div>
    <div class="table-responsive">
      <table v-if="users.length > 0" class="table table--basic table--basic-no-borders">
        <thead v-if="displayHeaders" class="users-simple-list__heading">
          <th>{{ t('activerecord.models.user.one') }}</th>
          <th />
          <th v-for="(header, index) in additionalHeaders" :key="`custom-header-${index}`">
            {{ header }}
          </th>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id" class="users-simple-list__row">
            <td class="users-simple-list__avatar">
              <Avatar :src="user.avatar" :title="user.name" />
            </td>
            <td class="table--data__nowrap">
              <a :href="user.show_path" :title="user.name">
                {{ truncate(user.name, 40) }}
              </a>
              <ToolTip
                v-if="user.on_break"
                icon="t-ringer-silent-sleeping"
                :title="t('vue_templates.user_profile.user_profile_details.user_on_break_message')"
                type="muted"
              />
              <br />
              <small v-if="user.role_label" class="text-muted">
                <i class="ic ic--larger" :class="user.role_icon" />
                {{ user.role_label }}
              </small>
            </td>
            <td>
              <slot name="additional-table-data" :user-prop="user" />
            </td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="paginationData"
        :pagination-data="paginationData"
        :updating="paginationUpdating"
        @page-changed="handlePageChanged"
      />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/atoms/Avatar/Index.vue';
import ToolTip from '@/components/atoms/ToolTip.vue';
import SearchInput from '@/components/atoms/SearchInput/Index.vue';
import LoadingSpinner from '@/components/atoms/LoadingSpinner/Index.vue';
import i18n from '@/mixins/i18n';
import filterTruncate from '@/mixins/filterTruncate';

export default {
  name: 'UsersSimpleList',

  components: {
    Avatar,
    LoadingSpinner,
    SearchInput,
    ToolTip,
  },
  mixins: [i18n, filterTruncate],

  props: {
    endpoint: String,
    displayHeaders: {
      type: Boolean,
      default: false,
    },
    additionalHeaders: Array,
  },

  data: function () {
    return {
      loading: true,
      users: [],
      search: '',
      page: 1,
      per: 5,
      paginationUpdating: false,
      paginationData: null,
    };
  },

  computed: {
    currentParams: function () {
      return {
        page: this.page,
        per: this.per,
        name: this.search,
        ongoing: this.ongoing,
      };
    },
  },

  watch: {
    search: _.debounce(function () {
      this.reloadUsers();
    }, 500),
  },

  created: function () {
    this.getUsers();
  },

  methods: {
    reloadUsers: function () {
      this.users = [];
      this.loading = true;
      this.page = 1;
      this.getUsers();
    },

    getUsers: function () {
      this.$http.get(this.endpoint, { params: this.currentParams }).then((response) => {
        const users = response.body && response.body.users ? response.body.users : response.body;
        this.users = users;
        this.paginationData = response.body.pagination;
        this.loading = false;
        this.paginationUpdating = false;
      });
    },

    handlePageChanged: function (newPage) {
      this.paginationUpdating = true;
      this.page = newPage;
      this.getUsers();
    },
  },
};
</script>

<style scoped lang="scss">
.users-simple-list__results-for {
  display: inline-block;
}
.users-simple-list__heading > th {
  border-top: none !important;
  background: none;
  font-size: 1.1em;
  padding-bottom: 20px;
  padding-left: 0;
  &:first-child {
    padding-left: 2px;
  }
}

.users-simple-list__avatar {
  width: 0px;
  padding-right: 0;
  text-align: center;
}

.users-simple-list__row > td {
  padding-left: 0;
  padding-bottom: 30px;
}
</style>
