const filterTruncate = {
  // Vue filter to truncate a string to the specified length.
  // @param {String} value The value string.
  methods: {
    truncate: function (value, length) {
      if (value == null) {
        return;
      }
      if (value.length < length) {
        return value;
      }
      const stringLength = length - 3;
      return value.substring(0, stringLength) + '...';
    },
  },
};

export default filterTruncate;
