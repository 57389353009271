<template>
  <Tile
    v-if="json"
    :data-test-id="`event-tile-${json.id}`"
    :display-name="json.name || json.event?.name"
    :sub-heading="json.details"
    :item-image-url="json.image_url || json.event?.image_url"
    :json="json"
    :locked="locked"
    :locked-until-tasked="lockedUntilTasked"
    :type="json.tile_type"
    :user-endpoint="userEndpoint"
    :carousel-notice-displayed="carouselNoticeDisplayed"
    :learnlist-notice-displayed="learnlistNoticeDisplayed"
    :modal-copy="modalCopy"
    :use-legacy-menu="true"
  >
    <template #body-properties>
      <div class="tile__details-container">
        <div v-if="json.dates" class="tile__details">
          <i class="ic ic-t-calendar-month-day-planner-events text-center" />
          {{ json.dates }}
          <small v-if="json.event_sessions_count && json.event_sessions_count > 1">
            {{ t('vue_templates.tile.multiple_sessions') }}
          </small>
        </div>
        <div
          v-if="json.location && isDisplayAllSessions()"
          class="tile__details--location"
          data-test-id="event-tile-location"
          v-html="json.location"
        />
        <div v-if="json.host" class="tile__details">
          <i class="ic ic-presenting text-center" />
          {{ json.host }}
        </div>
        <div
          v-if="isDisplayMergedSessions()"
          class="tile__details--other_sessions border-top-1 m-t-2"
          data-test-id="event-tile-other-sessions"
        >
          {{ otherSessionsText(json.other_sessions_count) }}
        </div>
      </div>
    </template>
    <template #footer-content>
      <BookmarkButton
        :id="json.id"
        :data-test-id="`event-tile-${json.id}-bookmark`"
        :type="json.tile_type"
        :init-liked="json.liked"
      />
    </template>
  </Tile>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useTileProps } from '@/composables/useTileProps';
import BookmarkButton from '@/components/molecules/BookmarkButton.vue';
import Tile from '@/components/organisms/Tiles/BaseTile/Index.vue';

const { t } = useI18n();

export default defineComponent({
  name: 'EventSessionTile',
  components: {
    BookmarkButton,
    Tile,
  },

  props: {
    ...useTileProps('event'),
  },
  setup() {
    return {
      t,
    };
  },
  methods: {
    otherSessionsText(count: number | undefined) {
      if (count === undefined) return '';

      if (count === 0) {
        return t('vue_templates.tile.n_other_sessions.zero');
      } else {
        return t('vue_templates.tile.n_other_sessions', { count });
      }
    },
    isDisplayAllSessions() {
      return this.json?.other_sessions_count === undefined;
    },
    isDisplayMergedSessions() {
      return !this.isDisplayAllSessions();
    },
  },
});
</script>

<style scoped lang="scss">
$text-size: 12px;
$icon-size: 14px;

.tile__details-container {
  position: absolute;
  top: 215px;
  > div {
    margin-top: 5px;
  }
}

.tile__details {
  color: $dark-electric-blue;
  font-size: $text-size;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ic {
    font-size: $icon-size;
    margin-right: $text-size;
  }

  ::v-deep &--location {
    color: $dark-electric-blue;
    font-size: $text-size;
    .ic {
      font-size: $icon-size;
      margin-right: 8px;
    }
  }

  ::v-deep &--other_sessions {
    color: $dark-electric-blue;
    font-size: $text-size;
    font-style: italic;
    border: 1px solid;
    border-radius: 999em;
    padding: 3px 10px;
    width: fit-content;
  }
}
</style>
