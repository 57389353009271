<template>
  <header class="tile-header" :style="{ backgroundImage: showThumbGrid ? '' : `url(${imageUrl})` }">
    <div v-if="showThumbGrid" class="tile-header" :class="{ 'tile-header--tiled': showThumbGrid }">
      <img
        v-for="(item, index) in imageUrls"
        :key="`image-${index}`"
        :src="item && item.url ? item.url : imageUrl"
        alt=""
      />
    </div>
    <div
      v-if="tint && type === 'Learnlist'"
      data-test-id="tile-header-tint"
      class="tile-header tile-header--overlay"
      :style="{ backgroundColor: tint }"
    />
    <div v-if="isNew" data-test-id="tile-header-new-label" class="tile-header__new_label">
      {{ t('helpers.common.new') }}
    </div>
    <div
      v-if="completed"
      data-test-id="tile-header-completed-label"
      class="tile-header__completion"
    >
      <Icon icon-name="check" />
    </div>
    <div v-if="joined" data-test-id="tile-header-status-joined" class="tile-header__joined">
      {{ t('teams.searches.index.joined') }}
      <Icon icon-name="t-ok-successful-check" type="success" />
    </div>
    <div v-else-if="applied" data-test-id="tile-header-status-applied" class="tile-header__applied">
      {{ t('teams.searches.index.applied') }}
      <Icon icon-name="t-users-people-group-couple" type="warning" />
    </div>
    <div
      v-else-if="isPrivate"
      data-test-id="tile-header-status-private"
      class="tile-header__private"
    >
      {{ t('activerecord.attributes.team.privacies.private') }}
      <Icon icon-name="t-security-lock" />
    </div>
    <span v-if="locked || lockedUntilTasked">
      <span
        class="tile-header__link--locked"
        :style="lockedStyles"
        data-test-id="tile-header-overlay"
      >
        <p data-test-id="tile-header-locked-warning">
          {{ lockedText }}
        </p>
      </span>
    </span>
    <a v-else :href="url" :title="name">
      <span class="tile-header__link" />
      <span class="visually-hidden">{{ name }}</span>
    </a>
  </header>
</template>

<script>
import i18n from '@/mixins/i18n';
import Icon from '@/components/atoms/Icon/Index.vue';
import lockDarkImageSrc from 'images/lock.png';
import lockLightImageSrc from 'images/lock-white.png';

export default {
  name: 'TileHeader',

  components: {
    Icon,
  },

  mixins: [i18n],

  props: {
    showThumbGrid: Boolean,
    imageUrl: String,
    imageUrls: Array,
    tint: [String, Boolean],
    type: {
      type: String,
      validator: (propValue) => {
        const types = ['Learnlist'];
        const isValid = types.find((type) => type === propValue);
        return isValid;
      },
    },
    isNew: Boolean,
    completed: Boolean,
    joined: Boolean,
    applied: Boolean,
    isPrivate: Boolean,
    locked: Boolean,
    lockedUntilTasked: Boolean,
    url: String,
    name: String,
  },

  computed: {
    lockedStyles() {
      return {
        '--dark-lock-bg': `url('${lockDarkImageSrc}')`,
        '--light-lock-bg': `url('${lockLightImageSrc}')`,
      };
    },
    lockedText() {
      return this.lockedUntilTasked
        ? this.t('vue_templates.tile.locked_until_set_as_a_task')
        : this.t('vue_templates.tile.complete_previous_to_unlock');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'styles.scss';

.tile-header {
  background-size: cover;
  height: 153px;
  position: relative;
  text-align: center;
}

.tile-header__new_label {
  position: absolute;
  top: 14px;
  left: 15px;
  padding: 5px 15px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: $white;
  background-color: $brand-primary;
  z-index: 5;
}

.tile-header__completion {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 15px;
  top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $cadmium-green;
  border-radius: 50px;
  background-color: $green;
  color: $white;
  font-weight: bold;
  z-index: 5;

  .ic {
    height: auto;
    top: 0;
  }
}

.tile-header__link {
  position: absolute;
  left: 0;
  top: 0;
  height: $header-height + $body-height - $footer-height; /* footer is positioned absolutely */
  width: 100%;
  background-image: none; // was empty.gif
  z-index: 6;

  &--locked {
    background: rgba(25, 25, 25, 0.3) var(--dark-lock-bg) no-repeat center;
    display: block;
    cursor: not-allowed;
    height: $header-height + $body-height;
    top: 0;

    p {
      position: absolute;
      color: $white;
      font-size: 18px;
      margin: 36px 40px;
      visibility: hidden;
    }

    &:hover {
      background: rgba(25, 25, 25, 0.8) var(--light-lock-bg) no-repeat center 120px;
    }

    &:hover p {
      visibility: visible;
    }
  }
}

.tile-header__joined,
.tile-header__applied,
.tile-header__private {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 15px;
  padding: 5px 15px 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  z-index: 5;
  background-color: $white;
  border: 1px solid $platinum;

  .ic {
    font-size: 15px;
    margin-top: 3px;
    margin-right: 8px;
  }
}

.tile-header--tiled {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;

  img {
    height: 50%;
    width: 50%;
    object-fit: cover;
  }
}

.tile-header--overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $brand-primary;
  opacity: 0.6;
  z-index: 4;
  pointer-events: none;
}
</style>
