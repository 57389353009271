<template>
  <CardSurface v-if="post">
    <div class="feed-post__content-wrapper">
      <!-- #region Header -->
      <div class="feed-post__header">
        <AvatarFullDescription
          :avatar-url="post.user.avatar"
          :is-pinned="post.pinned"
          :show-path="post.user.show_path"
          :full-name="post.user.name"
          :on-break="post.user.on_break"
          :job-title="post.user.job_title"
        />
        <div class="feed-post__menu-container">
          <Pips v-if="showMenu" type="muted" class="feed-post__menu">
            <li v-if="canPin">
              <JavascriptActionLink
                data-test-id="feed-post-pin-button"
                :label="pinMenuLabel"
                @action="onPinClick"
              />
            </li>
            <li v-if="canEdit">
              <JavascriptActionLink
                data-test-id="feed-post-edit-button"
                :label="t('team_social_feed.post.edit')"
                @action="onEditClick"
              />
            </li>
            <li v-if="canDelete">
              <JavascriptActionLink
                data-test-id="feed-post-delete-button"
                :label="t('team_social_feed.post.delete')"
                @action="onDeleteClick"
              />
            </li>
          </Pips>
          <span class="feed-post__timestamp small text-muted">{{ post.created_at_words }}</span>
        </div>
      </div>
      <!-- #endregion Header -->

      <!-- #region Edit-mode Content -->
      <FeedPostInput
        v-if="isEditing"
        ref="feed-post-input"
        :error="postError"
        :focus-on-load="true"
        :at-mention-params="atMentionParams"
        :link-loading="linkScraperLoading"
        :link-error="linkScraperHasError"
        :link="editedPost?.link"
        :model-value="editedPost?.content"
        :image="editedPost?.image_url"
        :video="editedPost?.video"
        :video-preview="videoPreview || editedPost?.video"
        :team-posts-presigns-path="teamPostsPresignsPath"
        data-test-id="feed-post-input-element"
        :primary-button-text="t('team_social_feed.post.edit')"
        :disabled="isEditDisabled"
        :always-show-buttons="true"
        @cancel="onEditCancel"
        @update:modelValue="onEditInput"
        @submit="onEditSubmit"
        @image-added="handleImageAdded"
        @image-removed="handleImageRemoved"
        @link-inserted="handleLinkInserted"
        @link-removed="handleLinkRemoved"
        @video-added="handleVideoAdded"
        @video-preview-loaded="handleVideoPreview"
        @video-removed="handleVideoRemoved"
      />
      <!-- #endregion Edit-mode Content -->

      <!-- #region Static Content -->
      <div v-else>
        <div
          ref="content"
          :style="{
            '--contentMaxHeight': contentMaxHeight,
          }"
          :class="{
            'feed-post__content--showing': contentHasOverflow && showReadMore,
            'feed-post__content--hidden': contentHasOverflow && !showReadMore,
          }"
          class="feed-post__content"
          data-test-id="feed-post-content"
          v-html="post.content"
        />
      </div>
      <!-- #endregion Static Content -->

      <!-- #region Read More Toggle -->
      <div class="feed-post__read-more m-b-2">
        <TextLink
          v-if="contentHasOverflow && !showReadMore && !isEditing"
          data-test-id="feed-post-read-more"
          @click="toggleReadMore"
        >
          {{ t('team_social_feed.post.read_more') }}
        </TextLink>
        <TextLink
          v-if="contentHasOverflow && showReadMore && !isEditing"
          data-test-id="feed-post-read-less"
          @click="toggleReadMore"
        >
          {{ t('team_social_feed.post.read_less') }}
        </TextLink>
      </div>
      <!-- #endregion Read More Toggle -->

      <!-- #region Embeddables -->
      <div v-if="!isEditing">
        <!-- #region Embeddables - Image -->
        <div v-if="post.image_url" class="feed-post__image-container">
          <img class="feed-post__image" :src="post.image_url" data-test-id="image-embed" />
        </div>
        <!-- #endregion Embeddables - Image -->

        <!-- #region Embeddables - Video -->
        <div v-else-if="post.video" class="m-t-2">
          <PlayerEmbed :media-url="post.video" provider="html5" data-test-id="video-embed" />
        </div>
        <!-- #endregion Embeddables - Video -->

        <!-- #region Embeddables - Link Preview -->
        <div v-else-if="showPostLink" class="m-t-2">
          <PlayerEmbed
            v-if="showPostLinkPlayerEmbed"
            :media-url="editedPost?.link.embeddable.url"
            :provider="editedPost?.link.embeddable.provider"
            data-test-id="link-video-embed"
          />
          <PostLink v-else :link="editedPost?.link" />
        </div>
        <!-- #endregion Embeddables - Link Preview -->
      </div>
      <!-- #endregion Embeddables -->

      <Like
        v-if="!isEditing && post.likes.count && post.likes.count > 0"
        :liked="post.likes.liked"
        :liked-by="post.likes.liked_by"
        :likes-count="post.likes.count"
        class="m-y-1"
      />
    </div>

    <!-- #region Comment Thread -->
    <div v-if="post.new_comment_path && !isEditing" class="feed-post__section">
      <div class="feed-post__reply-box">
        <CustomButton
          v-if="post.likes.url"
          :action="onLikeClick"
          data-test-id="feed-post-like-button"
          :text="post.likes.liked ? t('helpers.common.liked') : t('helpers.common.like')"
          :icon="post.likes.liked ? 't-like-thumb-up-vote-filled' : 't-like-thumb-up-vote'"
          button-style="link"
        />
        <CustomButton
          data-test-id="feed-post-comment-button"
          :action="onCommentClick"
          :text="t('helpers.common.comment', { count: 1 })"
          icon="t-chat-discussion"
          button-style="link"
        />
      </div>
    </div>

    <CommentThread
      v-if="commentList && commentList.length > 0"
      class="feed-post__comment-thread"
      :comments="commentList"
      :current-user="currentUser"
      :user="post.user"
      :reply-path="post.new_comment_path"
      :at-mention-endpoint="atMentionParams && atMentionParams.endpoint"
    />
    <!-- #endregion Comment Thread -->

    <!-- #region Footer -->
    <FeedPostFooter
      v-model="newComment"
      :is-focused="isFocused"
      :user="post.user"
      :current-user="currentUser"
      :loading="loading"
      :post-id="post.id"
      :at-mention-endpoint="atMentionParams && atMentionParams.endpoint"
      @submit="onNewComment"
    />
    <!-- #endregion Footer -->
  </CardSurface>
</template>

<script lang="ts">
// Modules
import { PropType } from 'vue';
import _ from 'lodash';
import { notify } from '@kyvg/vue3-notification';
// Composables
import { ITinyMceAtMentionProps } from '@/composables/useAtMention';
// Types
import { IFeedPost, IFeedPostComment } from '@/types/Team/Post';
// Mixins
import i18n from '@/mixins/i18n';
import linkScraper from '@/mixins/link-scraper.js';
import customConfirm from '@/mixins/customConfirm';
import queryHandler from '@/mixins/query-handler';
// Components
import AvatarFullDescription from '@/components/molecules/Avatar/FullDescription.vue';
import CardSurface from '@/components/atoms/CardSurface/Index.vue';
import CommentThread from '@/components/organisms/CommentThread.vue';
import CustomButton from '@/components/atoms/Button/Index.vue';
import FeedPostFooter from '@/components/molecules/FeedPost/Footer.vue';
import FeedPostInput from '@/components/molecules/FeedPost/Input.vue';
import JavascriptActionLink from '@/components/atoms/Menu/JavascriptActionLink.vue';
import Pips from '@/components/atoms/Menu/Pips.vue';
import TextLink from '@/components/atoms/TextLink.vue';
import PostLink from '@/components/molecules/PostLink/Index.vue';
import PlayerEmbed from '@/components/molecules/PlayerEmbed/Index.vue';
import Like from '@/components/atoms/Like/Index.vue';
import { IApiClient } from '@/types/composables/useApiClient';

export default {
  name: 'FeedPost',
  compatConfig: {
    MODE: 3,
  },
  components: {
    AvatarFullDescription,
    CardSurface,
    CommentThread,
    CustomButton,
    TextLink,
    FeedPostFooter,
    FeedPostInput,
    JavascriptActionLink,
    Pips,
    PostLink,
    PlayerEmbed,
    Like,
  },
  mixins: [customConfirm, i18n, linkScraper, queryHandler],
  provide() {
    return {
      qandaId: () => this.post?.id,
      railsEnv: this.railsEnv,
      selectedCommentId: () => this.query.comment_id,
    };
  },
  props: {
    atMentionParams: Object as PropType<ITinyMceAtMentionProps>,
    initPost: Object as PropType<IFeedPost>,
    postEndpoint: String,
    currentUser: Object,
    tinymceStylesheet: String,
    newCommentAvatar: String,
    teamPostsPresignsPath: String,
    railsEnv: String,
  },
  data() {
    const commentList: IFeedPostComment[] = [];
    return {
      post: _.cloneDeep(this.initPost),
      editedPost: _.cloneDeep(this.initPost),
      videoPreview: '',
      showReadMore: false,
      contentHasOverflow: false,
      contentMaxHeight: '160px',
      commentList,
      loading: false,
      newComment: '',
      isEditing: false,
      isFocused: false,
      postError: false,
    };
  },
  computed: {
    isEditDisabled(): boolean {
      return (
        _.isEqual(this.post, this.editedPost) ||
        !_.some(
          [
            this.editedPost?.video,
            this.editedPost?.image_url,
            this.editedPost?.image,
            this.editedPost?.content,
          ],
          Boolean
        )
      );
    },
    canEdit(): boolean {
      return !!this.post?.edit_path;
    },
    canDelete(): boolean {
      return !!this.post?.delete_path;
    },
    canPin(): boolean {
      return !!this.post?.pin_path;
    },
    showMenu(): boolean {
      return !!this.post?.edit_path || !!this.post?.delete_path || !!this.canPin;
    },
    pinMenuLabel(): string {
      return this.post?.pinned
        ? this.t('team_social_feed.post.unpin')
        : this.t('team_social_feed.post.pin');
    },
    showPostLink(): boolean {
      return !!(this.editedPost?.link?.url && this.editedPost?.link?.title);
    },
    showPostLinkPlayerEmbed(): boolean {
      return !!(
        this.editedPost?.link?.embeddable?.url && this.editedPost?.link?.embeddable?.provider
      );
    },
  },
  watch: {
    initPost: {
      handler(newPost: IFeedPost) {
        this.post = newPost;
      },
      deep: true,
    },
  },
  async mounted() {
    // The component can either be provided with a post as a prop 'initPost' or it will fetch
    // the post data using the 'postEndpoint'
    if (this.postEndpoint) {
      const response = await (this.$http as IApiClient).get(this.postEndpoint);
      this.setInitialPost(response.body);
      this.setInitialComments();
    } else {
      this.setInitialComments();
    }

    await this.$nextTick();

    if (this.$refs.content) {
      /* istanbul ignore next */
      this.contentHasOverflow =
        (this.$refs.content as HTMLDivElement).offsetHeight <
        (this.$refs.content as HTMLDivElement).scrollHeight;
      /* istanbul ignore next */
      this.contentMaxHeight = `${(this.$refs.content as HTMLDivElement).scrollHeight}px`;
    }
  },
  methods: {
    notify,
    handleVideoAdded(video: string): void {
      this.editedPost = Object.assign({}, this.editedPost, { video_path: video, video });
    },
    handleVideoPreview(videoUrl: string): void {
      this.videoPreview = videoUrl;
    },
    handleVideoRemoved(): void {
      this.videoPreview = '';
      // If image is set, null value will remove image from post.
      // undefined values are stripped from request params.
      const videoPathValue = this.post?.video ? null : undefined;
      this.editedPost = Object.assign({}, this.editedPost, {
        video_path: videoPathValue,
        video: undefined,
      });
    },
    handleImageAdded(imageAttachment: string): void {
      this.editedPost = Object.assign({}, this.editedPost, { image: imageAttachment });
    },
    handleImageRemoved(): void {
      this.editedPost = Object.assign({}, this.editedPost, { image: null, image_url: undefined });
    },

    setInitialPost(post: IFeedPost): void {
      this.post = _.cloneDeep(post);
      this.editedPost = _.cloneDeep(post);
    },
    setInitialComments(): void {
      if (this.post?.comments && this.post.comments.length > 0) {
        this.commentList = this.post.comments.map((comment) => ({
          ...comment,
          loading: false,
        }));
      }
    },
    toggleReadMore(): void {
      this.showReadMore = !this.showReadMore;
    },
    onCommentClick(): void {
      this.isFocused = true;
    },
    async onLikeClick(): Promise<void> {
      if (!this.post || !this.post.likes) {
        this.notify({
          type: 'error',
          title: this.t('team_social_feed.notifications.something_went_wrong'),
        });
        return;
      }

      const {
        likes: { url: likePath, liked },
        id,
      } = this.post;
      const action = liked ? 'unlike' : 'like';

      if (!likePath) {
        this.notify({
          type: 'error',
          title: this.t('team_social_feed.notifications.something_went_wrong'),
          text: this.t('team_social_feed.notifications.like_post.error', { action }),
        });
        return;
      }
      const deletePath = `${likePath}/${id}`;
      const likeParams = { like: { likeable_type: 'Teams::Post', likeable_id: id } };

      const http = liked
        ? {
            method: 'delete',
            path: deletePath,
            params: { body: likeParams },
          }
        : {
            method: 'post',
            path: likePath,
            params: likeParams,
          };
      try {
        const response = await (this.$http as IApiClient)[http.method](http.path, http.params);
        this.post.likes = response.body;
      } catch (e) {
        this.notify({
          type: 'error',
          title: this.t('team_social_feed.notifications.something_went_wrong'),
          text: this.t('team_social_feed.notifications.like_post.error', { action }),
        });
      }
    },
    deletePost(): void {
      /* istanbul ignore next */
      if (!this.post?.delete_path) return;

      (this.$http as IApiClient).delete(this.post.delete_path).then(() => {
        this.notify({
          type: 'success',
          title: this.t('team_social_feed.notifications.delete_post.success'),
        });
        this.$emit('delete');
      });
    },
    onDeleteClick(): void {
      this.confirmDelete(
        () => {
          this.deletePost();
        },
        {
          entity: this.t('team_social_feed.post.a_team_post'),
          button: this.t('team_social_feed.post.delete_post'),
        }
      );
    },
    onEditClick(): void {
      this.isEditing = true;
    },
    onEditCancel(): void {
      this.isEditing = false;
      this.videoPreview = '';
      this.postError = false;
      this.editedPost = _.cloneDeep(this.post);
      this.resetScrape();
    },
    onEditInput(value: string): void {
      this.editedPost = Object.assign({}, this.editedPost, { content: value });
    },
    onEditSubmit() {
      /* istanbul ignore next */
      if (!this.post?.edit_path) return;

      const postParams = _.omitBy(
        {
          content: this.editedPost?.content,
          link_attributes: this.editedPost?.link ? [this.editedPost?.link] : undefined,
          video_path: this.editedPost?.video_path,
          image: this.editedPost?.image,
        },
        _.isUndefined
      );

      (this.$http as IApiClient).put(this.post.edit_path, { post: postParams }).then(
        (response) => {
          // TODO: Investigate possible backend bug where removing a link OR video
          // on 'put' returns the original link in the response and only removes
          // the link when fetching all posts
          this.post = _.cloneDeep(response.body);
          this.editedPost = _.cloneDeep(response.body);

          this.$emit('update');
          this.isEditing = false;
          this.videoPreview = '';

          this.notify({
            type: 'success',
            title: this.t('team_social_feed.notifications.edit_post.success'),
          });
        },
        () => {
          this.postError = true;
          this.notify({
            type: 'error',
            title: this.t('team_social_feed.notifications.something_went_wrong'),
            text: this.t('team_social_feed.notifications.edit_post.error'),
            duration: -1,
          });
        }
      );
      this.resetScrape();
    },
    onNewComment(): void {
      /* istanbul ignore next */
      if (!this.post?.new_comment_path) return;

      this.loading = true;
      (this.$http as IApiClient)
        .post(this.post.new_comment_path, { content: this.newComment })
        .then(
          (commentResponse) => {
            this.commentList.push({ ...commentResponse.body, loading: false });
            this.newComment = '';

            /* istanbul ignore next */
            this.notify({
              type: 'success',
              title: this.t('team_social_feed.notifications.new_comment.success'),
            });
          },
          /* istanbul ignore next */
          () => {
            this.notify({
              type: 'error',
              title: this.t('team_social_feed.notifications.new_comment.error'),
            });
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    handleLinkRemoved(): void {
      this.resetScrape();
      if (this.post?.link && this.post?.link.url) {
        this.editedPost = Object.assign({}, this.editedPost, { link: { _destroy: true } });
      } else {
        this.editedPost = Object.assign({}, this.editedPost, { link: undefined });
      }
    },
    handleLinkInserted(url: string): void {
      // Method defined in link-handler mixin.
      this.createScrape(url);
    },
    // Called via link-handler mixin.
    onLinkScraperSuccess(): void {
      this.editedPost = Object.assign({}, this.editedPost, { link: this.linkScraperResponse });
    },
    onPinClick(): void {
      /* istanbul ignore next */
      if (!this.post?.pin_path) return;

      const notifyKey = this.post?.pinned ? 'unpin_post' : 'pin_post';

      (this.$http as IApiClient)
        .put(this.post.pin_path, {
          post: {
            pinned: !this.post.pinned,
          },
        })
        .then(
          () => {
            this.notify({
              type: 'success',
              title: this.t(`team_social_feed.notifications.${notifyKey}.success`),
            });
            this.$emit('pin-click');
          },
          () => {
            this.notify({
              type: 'error',
              title: this.t('team_social_feed.notifications.something_went_wrong'),
              text: this.t(`team_social_feed.notifications.${notifyKey}.error`),
            });
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-post__card {
  padding: 0;
}

.feed-post__content-wrapper {
  padding: 20px 30px;
}

.feed-post__content {
  max-height: 160px;
  transition: max-height 0.5s ease;
  overflow: hidden;

  &--showing {
    max-height: var(--contentMaxHeight);
  }

  &--hidden {
    mask-image: linear-gradient(to bottom, white 60%, transparent 100%);
  }
}

.feed-post__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feed-post__image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.feed-post__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.feed-post__section {
  border-top: 1px solid $light-gray;
}

.feed-post__comment-thread {
  border-top: 1px solid $light-gray;
  padding: 30px 70px 10px 70px;

  @media #{$mobile} {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.feed-post__reply-box {
  padding: 0 72px;
}

.feed-post__read-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.feed-post__menu-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  padding-top: 20px;
}

.feed-post__menu {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  transition: opacity ease 0.3s;
}
</style>
